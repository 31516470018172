import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Tooltip,
  MenuList,
  Typography,
  Divider,
} from '@mui/material';
import {
  faFileCirclePlus,
  faGear,
  faGlobe,
  faListTree,
  faUserGear,
} from '@fortawesome/pro-regular-svg-icons';
import { APP_VERSION, GIT_HASH } from 'utils/environment';
import { PrivateURL, PublicURL } from 'Urls';
import { Link } from 'react-router-dom';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import usePermissions from 'features/Auth/hook/usePermissions';
import { MenuItemWithIcon } from './MenuItemWithIcon';

type SettingsIconButtonProps = {
  sx?: SxProps<Theme>;
};
export const SettingsIconButton = (props: SettingsIconButtonProps) => {
  const [menuElementRef, setMenuElementRef] = useState<HTMLButtonElement | null>(null);
  const { HasPerm, HasAnyPerms, Permissions, hasPerm } = usePermissions();
  const open = Boolean(menuElementRef);
  const handleUserButtonClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setMenuElementRef(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setMenuElementRef(null);
  };

  const { isCustomCodesEnabled, isCustomAttributesEnabled, isPublicAPIEnabled } =
    useFeatureFlags();
  return (
    <>
      <Tooltip title="Settings" placement="bottom">
        <IconButton onClick={handleUserButtonClick} sx={props.sx}>
          <FontAwesomeIcon icon={faGear} />
        </IconButton>
      </Tooltip>

      <Menu
        id="settings-menu"
        anchorEl={menuElementRef}
        open={open}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <MenuList sx={{ width: '21rem', p: 0 }}>
          <HasAnyPerms
            permissions={[
              Permissions.ACCOUNT_ADMINISTRATION_ACCESS,
              Permissions.CUSTOM_CODES_ACCESS,
              Permissions.VIEW_APIKEY,
            ]}
          >
            <Typography variant="textSmallBold" sx={{ pl: 1, color: 'grey.500' }}>
              Account
            </Typography>

            <HasPerm permission={Permissions.ACCOUNT_ADMINISTRATION_ACCESS}>
              <Link to={PrivateURL.MANAGE_USERS} onClick={handleCloseUserMenu}>
                <MenuItemWithIcon
                  icon={faUserGear}
                  label="Account administration"
                  description="Organize your workspace structure by adding & managing scopes"
                />
              </Link>
            </HasPerm>

            {isCustomCodesEnabled && (
              <HasPerm permission={Permissions.CUSTOM_CODES_ACCESS}>
                <Link to={PrivateURL.CUSTOM_CODES} onClick={handleCloseUserMenu}>
                  <MenuItemWithIcon
                    icon={faFileCirclePlus}
                    label="Format settings"
                    description="Add custom formats for estimate upload."
                  />
                </Link>
              </HasPerm>
            )}
            {isPublicAPIEnabled && hasPerm(Permissions.VIEW_APIKEY) && (
              <Link to={PrivateURL.PUBLIC_API} onClick={handleCloseUserMenu}>
                <MenuItemWithIcon
                  icon={faGlobe}
                  label="Public API"
                  description="Configure external data access and manipulation"
                />
              </Link>
            )}
            {isCustomAttributesEnabled && (
              <Link to={PrivateURL.CUSTOM_ATTRIBUTES} onClick={handleCloseUserMenu}>
                <MenuItemWithIcon
                  icon={faListTree}
                  label="Attributes"
                  description="Create and manage custom attributes."
                />
              </Link>
            )}
            <Divider sx={{ my: '.25rem !important' }} />
          </HasAnyPerms>
          <Typography variant="textSmallBold" sx={{ pl: 1, color: 'grey.500' }}>
            Other
          </Typography>

          <a href={PublicURL.OMNICLASS} target="_blank">
            <MenuItem onClick={handleCloseUserMenu}>Omniclass Disclaimer</MenuItem>
          </a>
          <a href={PublicURL.LANDING} target="_blank">
            <MenuItem onClick={handleCloseUserMenu}>
              &copy; {new Date().getFullYear()} ConCntric, Inc &nbsp;
              {`v${APP_VERSION}(${GIT_HASH})`}
            </MenuItem>
          </a>
        </MenuList>
      </Menu>
    </>
  );
};
