import { useFlags } from 'flagsmith/react';
import { IFlags } from 'flagsmith/types';

export const AVAILABLE_FEATURE_FLAGS = [
  'TENANT_IS_USING_CUSTOM_TEMPLATE',
  'advance_filters',
  'allow_items_import',
  'bid_package_pull_plan_available',
  'building_connected_integration_available',
  'building_connected_production',
  'calibrate_multiple_components',
  'context',
  'custom_codes',
  'custom_normalization',
  'dashboard',
  'ediphi_estimates',
  'estimate_upload_flow_v2',
  'foresite_chart_zoom',
  'issue_management',
  'markup_templates',
  'notification',
  'procurement_available',
  'procurement_bid_package_details',
  'procurement_custom_format',
  'public_api',
  'public_api_submit',
  'pwa',
  'reporting',
  'sage_xml_estimates',
  'tvd_mf_report',
  'white_labeling',
  'concept_lab',
  'enable_custom_attributes',
] as const;

export const useFeatureFlags = () => {
  const flags = useFlags(AVAILABLE_FEATURE_FLAGS);
  return {
    isNotificationEnabled: flags.notification.enabled,
    isCustomNormalizationEnabled: flags.custom_normalization.enabled,
    isForeSiteChartZoomEnabled: flags.foresite_chart_zoom.enabled,
    isCustomCodesEnabled: flags.custom_codes.enabled,
    isBidPackageDetailsEnabled: flags.procurement_bid_package_details.enabled,
    isPWAEnabled: flags.pwa.enabled,
    isReportingEnabled: flags.reporting.enabled,
    isTVDMFReportEnabled: flags.tvd_mf_report.enabled,
    isContextEnabled: flags.context.enabled,
    isIssueManagementEnabled: flags.issue_management.enabled,
    isDashboardEnabled: flags.dashboard.enabled,
    isProcurementEnabled: flags.procurement_available.enabled,
    isProcurementCustomFormatEnabled: flags.procurement_custom_format.enabled,
    isWhitelabelingEnabled: flags.white_labeling.enabled,
    isItemsImportEnabled: flags.allow_items_import.enabled,
    isAdvancedFiltersEnabled: flags.advance_filters.enabled,
    isMarkupTemplatesEnabled: flags.markup_templates.enabled,
    isPublicAPIEnabled: flags.public_api.enabled,
    isPublicAPISubmitEnabled: flags.public_api_submit.enabled,
    isTenantUsingCustomTemplate: flags.TENANT_IS_USING_CUSTOM_TEMPLATE.enabled,
    isBidPackagePullPlanEnabled: flags.bid_package_pull_plan_available.enabled,
    isBuildingConnectedIntegrationEnabled:
      flags.building_connected_integration_available.enabled,
    useProductionBuildingConnected: flags.building_connected_production.enabled,
    isEstimateUploadFlowV2Enabled: flags.estimate_upload_flow_v2.enabled,
    isEdiphiEstimatesEnabled: flags.ediphi_estimates.enabled,
    isSageXmlEstimatesEnabled: flags.sage_xml_estimates.enabled,
    isCalibrateMultipleComponentsEnabled: flags.calibrate_multiple_components.enabled,
    isConceptLabEnabled: flags.concept_lab.enabled,
    isCustomAttributesEnabled: flags.enable_custom_attributes.enabled,
  };
};

export const getFallbackFeatureFlags = () =>
  AVAILABLE_FEATURE_FLAGS.reduce<IFlags>((acc, key) => {
    acc[key] = { enabled: false };
    return acc;
  }, {});

export type FlagsmithKeys = (typeof AVAILABLE_FEATURE_FLAGS)[number];
export type FeatureFlagsKeys = keyof ReturnType<typeof useFeatureFlags>;
