import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { History, Layout } from 'Urls';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useClearProjectData } from 'features/Projects/hook/project';
import { LocationState } from 'types/globals';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { usePwa } from 'components/PwaProvider/usePwa';
import { useCurrentUser } from './useCurrentUser';
import { clearSession, setSession } from './sessionHelpers';
import { Session } from '../types';
import { useFlagsmith } from 'flagsmith/react';

const USERNAME_LS_KEY = 'concntric-login-last-username';

const useClearCacheData = () => {
  const queryClient = useQueryClient();
  const clearCacheData = () => {
    queryClient.cancelQueries();
    queryClient.clear();
  };
  return {
    clearCacheData,
  };
};

export const useSessionActions = () => {
  const location = useLocation<LocationState>();
  const { clearProjectData } = useClearProjectData();
  const storedUsername = localStorage.getItem(USERNAME_LS_KEY);
  const { clearCurrentUser, validateTenantAndLoadUser } = useCurrentUser();
  const { clearCacheData } = useClearCacheData();
  const {
    updateServiceWorker,
    needRefresh: [needRefresh, setNeedRefresh],
  } = usePwa();
  const { isPWAEnabled } = useFeatureFlags();
  const flagsmith = useFlagsmith();

  const clearSessionData = () => {
    clearSession();
    clearProjectData();
    clearCurrentUser();
    clearCacheData();
    flagsmith.logout();
  };

  const login = async (email: string, password: string, rememberMe = false) => {
    const sessionData = await ApiService.post(Resources.AUTH, {
      email: email.toLowerCase(),
      password,
    }).then(({ data }) => data as Session);

    setSession({ ...sessionData, persist: rememberMe });
    await validateTenantAndLoadUser({ isLogin: true });
    if (needRefresh && isPWAEnabled) {
      setNeedRefresh(false);
      await updateServiceWorker(true);
      window.location.reload();
    }
    setTimeout(() => {
      const next = location.state?.from?.pathname ? location.state.from : Layout.ROOT;
      History.push(next);
    }, 0);
  };

  return { clearSessionData, storedUsername, login };
};
